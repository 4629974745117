import moment from 'moment';
import { fromJS } from 'immutable';
import { markAsSync, markAsSideEffect } from '@tradetrax/web-common';
import { subsService } from 'services';
import { checkValidName } from './CustomRoleDetailsContext';

export { deleteRelevantTask, deleteLeadTimeTask } from '../../Users/MyProfile/MyProfileActions';

export function readCustomRole(roleId) {
  return subsService
    .getRole({}, { params: { roleId } })
    .then(fromJS)
    .then(response => state => state.set('customRole', response));
}

markAsSync(setTab);
export function setTab(state, tab) {
  return state.set('tab', tab);
}

markAsSync(setSelectedSection);
export function setSelectedSection(state, section) {
  return state.set('selectedSection', section);
}

markAsSideEffect(updateName);
export function updateName(_, name) {
  const isNameValid = checkValidName(name, this.customRoles);
  if (!isNameValid) throw new Error('name-already-exists');

  const { customRole } = this.state.toObject();
  const roleId = customRole.get('_id');
  const previousName = customRole.get('name');

  this.controller.dispatch([state => state.setIn(['customRole', 'name'], name)]);

  subsService
    .updateRole({ name }, { params: { roleId } })
    .then(() => this.settingsController.updateRoleName(customRole, name))
    .catch(error => {
      this.addAlert('There was a problem renaming this Custom Role. Please try again.', 'danger');
      this.controller.dispatch([state => state.setIn(['customRole', 'name'], previousName)]);
    });
}

// My Tasks actions
markAsSync(updateNotificationEventsSettings);
export function updateNotificationEventsSettings(state, onlyChannels, customRole) {
  const channels = (onlyChannels || []).filter(channel => !!channel);
  const roleId = customRole.get('_id');
  const notifications = {
    notificationsChannels: channels,
  };

  subsService.updateRole({ notifications }, { params: { roleId } }).catch(error => {
    this.controller.dispatch([
      state =>
        state.setIn(
          ['customRole', 'settings', 'notifications', 'notificationsChannels'],
          customRole.getIn(['settings', 'notifications', 'notificationsChannels'])
        ),
    ]);
  });

  return state.setIn(['customRole', 'settings', 'notifications', 'notificationsChannels'], fromJS(channels));
}

markAsSync(updateNotificationEvents);
export function updateNotificationEvents(state, customRole, event, { onlyChannels, notice_window, noticeTime }) {
  const roleId = customRole.get('_id');
  const eventState = customRole.getIn(['settings', 'notifications', event]);
  let payload = eventState;

  switch (event) {
    case 'date-change': {
      const current = eventState.get('notice_window') || 7;
      payload = payload.set('notice_window', notice_window || current);
      break;
    }
    case 'task-missed-check-in':
    case 'update-request-reminder':
    case 'end-of-day-reminder': {
      const current = eventState.get('noticeTime') || 14;
      const selectedTime = noticeTime && moment(noticeTime, 'hha').hours();
      payload = payload.set('noticeTime', selectedTime || current);
      break;
    }
    case 'check-in-reminder': {
      const current = eventState.get('noticeTime') || 8;
      const selectedTime = noticeTime && moment(noticeTime, 'hha').hours();
      payload = payload.set('noticeTime', selectedTime || current);
      break;
    }
    default:
    //do nothing... prevent lint error.
  }

  if (onlyChannels) {
    payload = payload.set(
      'onlyChannels',
      onlyChannels.filter(channel => !!channel)
    );
  }
  subsService.updateRole({ notifications: { [event]: payload.toJS() } }, { params: { roleId } }).catch(error => {
    this.controller.dispatch([state => state.setIn(['customRole', 'settings', 'notifications', event], eventState)]);
  });

  return state.setIn(['customRole', 'settings', 'notifications', event], payload);
}

// Relevant Tasks actions
const formatRelevantTask = gtlTask => {
  let task = gtlTask;
  const onlyChannels = { onlyChannels: [] };
  if (!task.get('completed')) task = task.set('completed', onlyChannels);
  if (!task.get('in-progress')) task = task.set('in-progress', onlyChannels);
  if (!task.get('start-date-or-end-date'))
    task = task.set('start-date-or-end-date', { onlyChannels: [], notice_window: 7 });
  if (!task.get('check-in-or-check-out')) task = task.set('check-in-or-check-out', onlyChannels);
  if (!task.get('commitment-request-accepted')) task = task.set('commitment-request-accepted', onlyChannels);
  return task
    .delete('_id')
    .delete('__v')
    .delete('children')
    .delete('trade');
};

const relevantTaskViewModel = task => {
  const onlyChannels = fromJS({ onlyChannels: [] });
  return task
    .set('completed', onlyChannels)
    .set('in-progress', onlyChannels)
    .set('start-date-or-end-date', fromJS({ onlyChannels: [], notice_window: 7 }))
    .set('check-in-or-check-out', onlyChannels)
    .set('commitment-request-accepted', onlyChannels);
};

markAsSync(addRelevantTask);
export function addRelevantTask(state, task) {
  const { customRole } = state.toObject();
  const roleId = customRole.get('_id');
  const relevantTasks = customRole.getIn(['settings', 'notifications', 'relevantTasks']);

  const notifications = {
    relevantTasks: relevantTasks
      .push(task)
      .map(formatRelevantTask)
      .toJS(),
  };

  subsService.updateRole({ notifications }, { params: { roleId } }).catch(error => {
    this.alert.error({ message: 'There was a problem adding this Relevant Task. Please try again.' });
    this.controller.dispatch([
      state => state.setIn(['customRole', 'settings', 'notifications', 'relevantTasks'], relevantTasks),
    ]);
  });

  return state.updateIn(['customRole', 'settings', 'notifications', 'relevantTasks'], relevantTasks =>
    relevantTasks.unshift(relevantTaskViewModel(task))
  );
}

markAsSync(updateRelevantTask);
export function updateRelevantTask(state, task, event, channels, extra) {
  const { customRole } = state.toObject();
  const roleId = customRole.get('_id');
  const relevantTasks = customRole.getIn(['settings', 'notifications', 'relevantTasks']);
  const index = relevantTasks.indexOf(task);
  const update = task =>
    task.setIn(
      [event, 'onlyChannels'],
      channels.filter(x => !!x)
    );
  const body = update(task);
  const payload = {
    [event]: body.get(event).toJS(),
  };
  const notice_window = extra?.notice_window;
  if (event === 'start-date-or-end-date') payload['start-date-or-end-date'].notice_window = notice_window || 7;

  const notifications = {
    relevantTasks: relevantTasks
      .update(index, relevantTask => relevantTask.merge(payload))
      .map(formatRelevantTask)
      .toJS(),
  };
  subsService.updateRole({ notifications }, { params: { roleId } }).catch(error => {
    this.alert.error({ message: 'There was an error updating the Relevant Task' });
    this.controller.dispatch([
      state => state.setIn(['customRole', 'settings', 'notifications', 'relevantTasks'], relevantTasks),
    ]);
  });

  const updatedState = notice_window
    ? state.setIn(
        ['customRole', 'settings', 'notifications', 'relevantTasks', index, event, 'notice_window'],
        notice_window
      )
    : state;
  return updatedState.updateIn(['customRole', 'settings', 'notifications', 'relevantTasks', index], update);
}

markAsSideEffect(doDeleteRelevantTask);
export function doDeleteRelevantTask(task) {
  const { customRole } = this.state.toObject();
  const roleId = customRole.get('_id');
  const relevantTasks = customRole.getIn(['settings', 'notifications', 'relevantTasks']);
  const index = relevantTasks.indexOf(task);
  const updated = index > -1 ? relevantTasks.splice(index, 1) : relevantTasks;
  const notifications = {
    relevantTasks: updated.map(formatRelevantTask).toJS(),
  };
  this.controller.dispatch([
    state =>
      state.updateIn(['customRole', 'settings', 'notifications', 'relevantTasks'], relevantTasks =>
        relevantTasks.splice(index, 1)
      ),
  ]);
  return subsService
    .updateRole({ notifications }, { params: { roleId } })
    .then(() =>
      this.alert.success({
        message: 'Task successfully removed from the Relevant Task list.',
      })
    )
    .catch(error => {
      this.alert.error({ message: 'There was a problem removing this Relevant Task from the list. Please try again.' });
      this.controller.dispatch([
        state => state.setIn(['customRole', 'settings', 'notifications', 'relevantTasks'], relevantTasks),
      ]);
    });
}

// Lead Time Tasks
const formatLeadTimeTask = gtlTask => {
  let task = gtlTask;
  if (!task.get('onlyChannels')) task = task.set('onlyChannels', []);
  if (!task.get('leadTimeDays')) task = task.set('leadTimeDays', 0);
  if (!task.get('isChildTask')) task = task.set('isChildTask', false);
  if (!task.get('gtlTaskId')) task = task.set('gtlTaskId', task.get('_id'));
  return task
    .delete('_id')
    .delete('__v')
    .delete('children')
    .delete('parentTaskId')
    .delete('parentTaskName')
    .delete('trade');
};

export function createLeadTimeTask({ task }) {
  const { customRole } = this.state.toObject();
  const roleId = customRole.get('_id');
  const leadTimeTasks = customRole.getIn(['settings', 'notifications', 'leadTimeTasks']);
  const notifications = {
    leadTimeTasks: leadTimeTasks
      .push(task)
      .map(formatLeadTimeTask)
      .toJS(),
  };

  return subsService
    .updateRole({ notifications }, { params: { roleId } })
    .then(() => state =>
      state.updateIn(['customRole', 'settings', 'notifications', 'leadTimeTasks'], leadTimeTasks =>
        leadTimeTasks.unshift(task)
      )
    )
    .catch(error => {
      this.alert.error({ message: 'There was a problem adding this Task. Please try again.' });
      this.controller.dispatch([
        state => state.setIn(['customRole', 'settings', 'notifications', 'leadTimeTasks'], leadTimeTasks),
      ]);
      throw error;
    });
}

markAsSideEffect(doDeleteLeadTimeTask);
export function doDeleteLeadTimeTask(task) {
  const { customRole } = this.state.toObject();
  const roleId = customRole.get('_id');
  const leadTimeTasks = customRole.getIn(['settings', 'notifications', 'leadTimeTasks']);
  const index = leadTimeTasks.indexOf(task);
  const updated = index > -1 ? leadTimeTasks.splice(index, 1) : leadTimeTasks;
  const notifications = {
    leadTimeTasks: updated.map(formatLeadTimeTask).toJS(),
  };

  this.controller.dispatch([
    state =>
      state.updateIn(['customRole', 'settings', 'notifications', 'leadTimeTasks'], leadTimeTasks =>
        leadTimeTasks.splice(index, 1)
      ),
  ]);

  subsService
    .updateRole({ notifications }, { params: { roleId } })
    .then(() =>
      this.alert.success({
        message: 'Task successfully removed from the Lead Time list.',
      })
    )
    .catch(error => {
      this.alert.error({ message: 'There was a problem removing this Task from the list. Please try again.' });
      this.controller.dispatch([
        state => state.setIn(['customRole', 'settings', 'notifications', 'leadTimeTasks'], leadTimeTasks),
      ]);
    });
}

export function updateLeadTimeTask(task, channels, leadTimeDays) {
  const { customRole } = this.state.toObject();
  const roleId = customRole.get('_id');
  const leadTimeTasks = customRole.getIn(['settings', 'notifications', 'leadTimeTasks']);
  const index = leadTimeTasks.indexOf(task);
  const update = task =>
    task
      .setIn(
        ['onlyChannels'],
        channels.filter(x => !!x)
      )
      .setIn(['leadTimeDays'], leadTimeDays);
  const updated = update(task);
  const payload = {
    leadTimeDays: updated.get('leadTimeDays'),
    onlyChannels: updated.get('onlyChannels'),
  };

  const notifications = {
    leadTimeTasks: leadTimeTasks
      .update(index, leadTimeTasks => leadTimeTasks.merge(payload))
      .map(formatLeadTimeTask)
      .toJS(),
  };

  return subsService
    .updateRole({ notifications }, { params: { roleId } })
    .then(() => state => state.updateIn(['customRole', 'settings', 'notifications', 'leadTimeTasks', index], update))
    .catch(() => {
      this.alert.error({ message: 'There was an error updating the Lead Time Task.' });
      this.controller.dispatch([
        state => state.setIn(['customRole', 'settings', 'notifications', 'leadTimeTasks'], leadTimeTasks),
      ]);
    });
}

// Filter Sets
export function readFilterSets(roleId) {
  return subsService
    .readRoleJobFilters({}, { params: { roleId } })
    .then(filterSets => filterSets.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)))
    .then(fromJS)
    .then(filterSets => state => state.merge({ filterSets }));
}

markAsSideEffect(removeFilterSet);
export function removeFilterSet(filterSet) {
  const index = this.state.get('filterSets').indexOf(filterSet);
  const customRoleId = this.state.getIn(['customRole', '_id']);
  const filterId = filterSet.get('_id');
  subsService
    .unshareJobFilter({ ids: [customRoleId] }, { params: { filterId } })
    .then(() => {
      this.alert.success({ message: 'Filter Set successfully removed from Custom Role.' });
      this.controller.dispatch([state => state.update('filterSets', filterSets => filterSets.splice(index, 1))]);
    })
    .catch(() => {
      this.alert.error({
        message: 'There was a problem removing this Filter Set from the Custom Role. Please try again',
      });
    });
}

export function updateUserPermissions(user, permission, value) {
  const roleId = user.get('_id');

  return subsService
    .updateRole({ permissions: { [permission]: value } }, { params: { roleId } })
    .then(() => state =>
      state.updateIn(['customRole', 'settings', 'permissions'], permissions =>
        (permissions || Map()).set(permission, value)
      )
    )
    .catch(err => {
      this.alert.error({ message: 'There was a problem updating the permission. Please try again.' });
      throw err;
    });
}
