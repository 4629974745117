import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

const UnstyledStartButton = ({ className, controller }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const buttonRef = useRef(null);

  const handleClick = () => {
    if (isTouchDevice()) {
      if (isExpanded) {
        controller.start();
        setIsExpanded(false);
      } else {
        setIsExpanded(true);
      }
    } else {
      controller.start();
    }
  };

  useEffect(() => {
    if (isExpanded && isTouchDevice()) {
      const handleClickOutside = event => {
        if (buttonRef.current && !buttonRef.current.contains(event.target)) {
          setIsExpanded(false);
        }
      };
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  }, [isExpanded]);

  return (
    <button
      ref={buttonRef}
      className={`btn btn-success ${className} ${isExpanded ? 'expanded' : ''}`}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon="stopwatch" />
      <span>Start Day</span>
    </button>
  );
};

export const StartButton = styled(UnstyledStartButton)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: width 0.3s ease, border-radius 0.3s ease;
  margin-left: 15px;
  white-space: nowrap;

  > svg {
    font-size: 18px;
  }

  > span {
    visibility: hidden;
    opacity: 0;
    margin-left: 0;
    transition: opacity 0.3s ease, margin-left 0.3s ease, visibility 0.3s ease;
  }

  &:hover,
  &:focus,
  &.expanded {
    width: 120px;
    border-radius: 50rem;
    > span {
      visibility: visible;
      opacity: 1;
      margin-left: 8px;
    }
  }
`;
