import { useController } from '@tradetrax/web-common';
import { fromJS } from 'immutable';
import React from 'react';
import * as actions from './TimeTrackerActions';

const emptyState = fromJS({
  timeTrackingEnabled: true,
  currentState: 'not-started', // not-started|working|break|end
  breakTime: 0,
  clockInDate: null,
  clockOutDate: null,
  dateOfSummary: null,
  tasksSummary: [],
  workingTime: 0,
});

export const TimeTrackerContext = appContext => {
  const expanded = appContext.appState.get('expanded');

  const [state, controller] = useController(actions, emptyState, appContext);

  React.useEffect(() => {
    controller.initialize();
  }, [controller]);

  return { state, controller, expanded };
};
