import { useRef, useEffect, useState } from 'react';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useChannel, CHANNELS } from '@tradetrax/web-common/lib/useRealTime';
import { subsService as openTasksService } from 'services';
import { CALENDAR_VIEW, LIST_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';
import * as actions from './TaskActions';
import { useFilterContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';

export const emptyState = fromJS({
  tasks: [],
  totalCount: 10,
  maxCount: 10,
  isLoading: true,
});

export function TaskContext({ status }, appContext) {
  const loaderRef = useRef();
  const tableRef = useRef();
  const currentURL = new URL(document.URL);
  const initView = currentURL.hash === '#calendar' ? CALENDAR_VIEW : LIST_VIEW;
  const filterContext = useFilterContext(FILTER_NAME.OPEN_TASKS);
  const { filterState } = filterContext;
  const [state, controller] = useController(actions, emptyState.set('view', initView).set('status', status), {
    loaderRef,
    tableRef,
    ...appContext,
    openTasksService,
    filterState,
  });
  const user = appContext.appState.get('user');
  const view = state.get('view');
  const isStatusChange = state.get('status') !== status;
  const isOpen = status === 'open';
  const filter = filterState.get('values');
  const [oldFilter, setOldFilter] = useState(filter);

  useEffect(() => {
    if (view === LIST_VIEW) {
      const { _lastRenderedStartIndex: startIndex, _lastRenderedStopIndex: stopIndex } = loaderRef?.current;
      if (startIndex !== undefined || stopIndex !== undefined) {
        loaderRef.current.resetLoadMoreRowsCache();
        if (isStatusChange) {
          tableRef.current?.scrollToRow(0);
          controller.clearState(status);
        }
      }
    }
    controller.updateURLParams();
  }, [controller, status, view, isStatusChange]);

  useEffect(() => {
    if (status === 'completed' && view === CALENDAR_VIEW) {
      controller.toggleView();
    }
  }, [controller, view, status]);

  useEffect(() => {
    if (filter !== oldFilter) {
      setOldFilter(filter);
      controller.invalidateFilter();
    }
  }, [filter, controller, oldFilter]);

  useChannel(CHANNELS.ACCOUNT_TASK, user.get('accountId'), data => controller.updateTaskRealTime(data, isOpen));
  return {
    state,
    controller,
    loaderRef,
    tableRef,
    isLoading: state === emptyState,
    openTasksService,
    filterContext,
  };
}
