import React from 'react';
import { fromJS, Map, List } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useFiltersContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';
import { ALL_TASKS } from '@tradetrax/web-common/lib/OuttakeIntake/TasksBySelect';
import { useAppContext } from 'app/AppContext';
import * as actions from './IntakeActions';

export const SCHEDULER_TAB = 'scheduler';
export const SUPER_TAB = 'super';
export const INSTALLER_TAB = 'installer';
export const MINIMUM_BATCH_SIZE = 25;

export const USER_TYPE = {
  [SUPER_TAB]: {
    label: 'Super',
    dataField: 'userSuper',
    payloadField: 'userSuperId',
  },
  [SCHEDULER_TAB]: {
    label: 'Scheduler',
    dataField: 'userScheduler',
    payloadField: 'userSchedulerId',
  },
  [INSTALLER_TAB]: {
    label: 'Installer',
    dataField: 'installer',
    payloadField: 'installerId',
  },
};

export const FILTER_NAME_MAP = {
  [SUPER_TAB]: FILTER_NAME.INTAKE_BY_USER,
  [SCHEDULER_TAB]: FILTER_NAME.INTAKE_BY_USER,
  [INSTALLER_TAB]: FILTER_NAME.INTAKE_BY_INSTALLER,
};

const emptySelectedItems = fromJS({
  selectAll: false,
  notIncludeTaskIds: [],
  taskIds: [],
  taskNames: [],
  gtlTaskIds: [],
  currentAssignees: [],
});

const emptyState = fromJS({
  tab: SUPER_TAB,
  tasksBy: ALL_TASKS,
  community: {
    _id: 'aaaaaabbbbbbccccccdddddd',
    name: '',
  },
  allTasks: Map({
    tasks: List(),
    totalCount: 10,
    maxCount: 10,
  }),
  tasksByType: Map({
    tasks: List(),
    totalCount: 10,
    maxCount: 10,
  }),
  selectedTasks: {
    [SUPER_TAB]: emptySelectedItems,
    [SCHEDULER_TAB]: emptySelectedItems,
    [INSTALLER_TAB]: emptySelectedItems,
  },
  hasPermission: true,
});

function getFilter(tab) {
  const filterName = FILTER_NAME_MAP[tab];
  const filter = this.filtersState.getIn([filterName, 'values']);
  return filter;
}

export function IntakeController(communityId) {
  const appContext = useAppContext();
  const loaderRef = React.useRef();
  const { filtersState } = useFiltersContext();
  const [state, controller] = useController(actions, emptyState, {
    ...appContext,
    loaderRef,
    filtersState,
    getFilter,
  });
  const tab = state.get('tab');
  const filterName = FILTER_NAME_MAP[tab];
  const filter = filtersState.getIn([filterName, 'values']);
  const isFiltering = filtersState.getIn([filterName, 'isFiltering']);
  const [oldFilter, setOldFilter] = React.useState(filter);

  React.useEffect(() => {
    controller.readCommunity(communityId);
  }, [communityId, controller]);

  React.useEffect(() => {
    if (filter !== oldFilter) {
      controller.invalidateFilter();
      setOldFilter(filter);
    }
  }, [communityId, controller, filter, oldFilter]);

  return { state, controller, loaderRef, filterName, isFiltering };
}
