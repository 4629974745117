import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@tradetrax/web-common';
import { formatTimeSpan } from './formatTimeSpan';
import { useTimer } from './useTimer';
import { usePopover } from './usePopover';

export const WorkingPanel = ({ className, context }) => {
  const { controller, state, expanded } = context;
  const workingTime = state.get('workingTime');
  const timer = useTimer(workingTime);
  const [show, setShow] = usePopover('popover-working-panel');
  const endDayClick = () => {
    setShow(false);
    controller.endDay();
  };

  const popover = (
    <NoArrowPopOver
      id="popover-working-panel"
      className="bg-secondary"
      content={true}
      style={{ maxWidth: 350, borderRadius: 8 }}
    >
      <Popover.Content>
        <div className="d-flex flex-row justify-content-end">
          <Button variant="danger" className="font-weight-bold rounded-pill mr-3 px-3" onClick={endDayClick}>
            <FontAwesomeIcon icon="stopwatch" /> End Day
          </Button>
          <BreakButton className="btn btn-outline rounded-pill px-3" onClick={() => controller._break()}>
            Break
          </BreakButton>
        </div>
      </Popover.Content>
    </NoArrowPopOver>
  );

  return (
    <OverlayTrigger trigger="click" placement="bottom-end" show={show} onToggle={setShow} overlay={popover}>
      <WorkingButton
        className={cn('btn btn-link text-decoration-none', className, { active: show })}
        expanded={expanded}
      >
        <span className="badge badge-success px-2 py-1">WORKING</span>
        <span className="label-time text-dark">{formatTimeSpan(timer)}</span>
      </WorkingButton>
    </OverlayTrigger>
  );
};

const NoArrowPopOver = styled(Popover)`
  .arrow {
    display: none;
  }
`;

const BreakButton = styled.button`
  border-color: white !important;
  color: white !important;
  font-weight: bold;

  &:hover {
    background-color: ${colors.gray800} !important;
  }
`;

const WorkingButton = styled.button`
  border-radius: 6px;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${props => `${props.expanded ? 'column' : 'row'}`};
  @media (min-width: 992px) {
    flex-direction: row;
  }

  &.active,
  &:hover {
    background-color: ${colors.gray100};
  }

  > .badge-success {
    letter-spacing: 0.1px;
    margin-right: ${props => ` ${props.expanded ? '0' : '14px'}`};
    background-color: ${colors.green400};
    font-style: normal;
    @media (min-width: 992px) {
      margin-right: 14px;
    }
  }

  > .label-time {
    font-weight: bold;
    font-size: 20px;
  }
`;
