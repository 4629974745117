import React from 'react';
import styled from 'styled-components';
import { colors } from '@tradetrax/web-common/lib/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopoverOnButton } from '@tradetrax/web-common/lib/Popover';

export function SubTaskList({ subTasks, removeSubTask }) {
  if (!subTasks) return null;

  return (
    <UL>
      {subTasks.map(subTask => (
        <SubTaskItem key={subTask.get('_id')} subTask={subTask} removeSubTask={removeSubTask} />
      ))}
    </UL>
  );
}

const SubTaskItem = ({ subTask, removeSubTask }) => {
  const removeRef = React.useRef();

  return (
    <li>
      <div className="d-flex justify-content-between">
        <span>
          <PopoverOnButton
            confirmText="Remove"
            message="Once removed, Custom Sub-Tasks cannot be recovered."
            placement="bottom-start"
            onConfirm={() => removeSubTask(subTask)}
            btnRef={removeRef}
            style={PopoverStyle}
          >
            <button ref={removeRef} className="btn btn-link p-0 mr-2 text-secondary">
              <FontAwesomeIcon icon="trash" />
            </button>
          </PopoverOnButton>
          {subTask.get('name')}
        </span>
        <span>Custom Sub-Task</span>
      </div>
    </li>
  );
};

const UL = styled.ul`
  list-style: none;
  padding: 0;

  li {
    background-color: ${colors.blue100};
    padding: 1.5rem 1rem 1.5rem 1rem;
    margin-top: 0.5rem;
    border-radius: 6px;

    span:first-child {
      font-weight: bold;
    }

    span:last-child {
      color: ${colors.gray400};
    }
  }
`;

const PopoverStyle = {
border:'none',
boxShadow: '0px 1px 2px 0px rgba(51, 51, 51, 0.20)',
background: colors.pearl
};
